@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css");

:root {
  --box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  list-style: none;
}

.light {
  background-color: #ffffff;
  transition: all 500ms;
}

.medium {
  background-color: #efefef;
  transition: all 500ms;
}

.dark {
  background-color: #15202b;
  transition: all 500ms;
}

.gOne {
  background: linear-gradient(
    90deg,
    hsla(191, 75%, 60%, 1) 0%,
    hsla(248, 87%, 36%, 1) 100%
  );
}
.gTwo {
  background: linear-gradient(235deg, #ffffff 0%, #000f25 100%),
    linear-gradient(180deg, #6100ff 0%, #000000 100%),
    linear-gradient(
      235deg,
      #ffa3ac 0%,
      #ffa3ac 40%,
      #00043c calc(40% + 1px),
      #00043c 60%,
      #005d6c calc(60% + 1px),
      #005d6c 70%,
      #00c9b1 calc(70% + 1px),
      #00c9b1 100%
    ),
    linear-gradient(
      125deg,
      #ffa3ac 0%,
      #ffa3ac 40%,
      #00043c calc(40% + 1px),
      #00043c 60%,
      #005d6c calc(60% + 1px),
      #005d6c 70%,
      #00c9b1 calc(70% + 1px),
      #00c9b1 100%
    );
  background-blend-mode: soft-light, screen, darken, normal;
}

.gThree {
  background: linear-gradient(125deg, #ffffff 0%, #000000 100%),
    linear-gradient(
      200deg,
      #ffd9e8 0%,
      #ffd9e8 50%,
      #de95ba calc(50% + 1px),
      #de95ba 60%,
      #7f4a88 calc(60% + 1px),
      #7f4a88 75%,
      #4a266a calc(75% + 1px),
      #4a266a 100%
    ),
    linear-gradient(
      113deg,
      #ffd9e8 0%,
      #ffd9e8 40%,
      #de95ba calc(40% + 1px),
      #de95ba 50%,
      #7f4a88 calc(50% + 1px),
      #7f4a88 70%,
      #4a266a calc(70% + 1px),
      #4a266a 100%
    );
  background-blend-mode: overlay, overlay, normal;
}

header {
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 70px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
}

header .logo {
  display: flex;
  align-items: center;
}

header .logo img {
  max-width: 35px;
  cursor: pointer;
}

header .logo span {
  font-size: 22px;
  margin-left: 7px;
  cursor: pointer;
  color: #525252;
}

header .themeSelector span {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
  border: 1px solid #dfdfdf;
}

header .themeSelector .activeTheme {
  height: 30px;
  width: 30px;
}

.addTask {
  padding: 20px 15px;
  border-radius: 5px;
  background-color: #ffffff;
  max-width: 500px;
  margin: 20px auto;
  box-shadow: var(--box-shadow);
}

.addTask form {
  display: flex;
  gap: 7px;
}

.addTask form input {
  flex-grow: 1;
  padding: 5px 5px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  font-size: 18px;
}

.addTask form input:focus {
  outline: 0.5px solid #eaeaea;
}

.addTask form button {
  background-color: #03ba5c;
  color: #ffffff;
  border: none;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

.addTask form button:hover {
  background-color: #03b056;
}

.showTask {
  padding: 20px 15px;
  border-radius: 5px;
  background-color: #ffffff;
  margin: 20px auto;
  box-shadow: var(--box-shadow);
}

.showTask .head {
  padding: 10px 0px 20px 0px;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.showTask .title {
  font-size: 18px;
  font-weight: 600;
  user-select: none;
}

.showTask .count {
  background-color: #dfdfdf;
  color: #525252;
  padding: 5px 10px;
  border-radius: 50%;
  margin-left: 10px;
  user-select: none;
}

.showTask .clearAll {
  background-color: #0f6af3;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  padding: 5px 10px;
  border: none;
  
}

.showTask .clearAll:hover {
  background-color: #1061db;
}

.showTask ul {
  margin: 30px 5px 20px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 15px;
}

.showTask li {
  padding: 15px 10px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  gap: 5px;
  width: 350px;
  box-shadow: var(--box-shadow);
  border-radius: 5px;
  border-left: 5px solid #1365dfaf;
}

.showTask li:hover {
  background-color: #f8f8f8;
}

.showTask li p {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.showTask li .name {
  font-size: 18px;
  padding-bottom: 5px;
}

.showTask li .time {
  font-size: 12px;
  color: #737373;
}

.showTask i.bi-pencil-square {
  font-size: 18px;
  color: #1363df;
  cursor: pointer;
}

.showTask i.bi-trash {
  font-size: 18px;
  color: #d82148;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .showTask li {
    width: 100%;
  }
}
